import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  modal: {
    position: 'absolute',
    top: '10%',
    width: '100vw',

    '& > .ReferaModal-root': {
      maxWidth: '728px !important',
    },
  },
  modalTitle: {
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontSize: '18px',
    color: Theme.Colors.Primary.Base,
  },
  divider: {
    backgroundColor: Theme.Colors.Grayscale.Twelve,
  },
  form: {
    display: 'grid',
    gap: spacing(24),
    gridTemplateAreas: `"clientType cpf" "name email" "phones ." "date hour"`,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  clientType: {
    gridArea: 'clientType',
  },
  cpf: {
    gridArea: 'cpf',
  },
  name: {
    gridArea: 'name',
  },
  email: {
    gridArea: 'email',
  },
  phones: {
    gridArea: 'phones',
  },
  date: {
    gridArea: 'date',
  },
  hour: {
    gridArea: 'hour',
  },
  modalButtons: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
  },
  iconButton: {
    fontWeight: Theme.Typography.FontWeight.Small,
    fontSize: Theme.Typography.FontSize.XXSmall,
    color: Theme.Colors.Primary.Base,
    margin: 0,
    padding: 0,
    marginRight: '5px',
  },
  icon: {
    height: spacing(16),
    width: spacing(16),
    marginRight: '8px',
  },
  labelTime: {
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontStyle: 'normal',
    fontSize: Theme.Typography.FontSize.Tiny,
    color: Theme.Colors.Grayscale.OneThousand,
  },
  input: {
    fontSize: Theme.Typography.FontSize.XXSmall,
  },
  timeInfo: {
    fontWeight: Theme.Typography.FontWeight.Small,
    fontFamily: Theme.Typography.FontFamily.Roboto,
    fontSize: Theme.Typography.FontSize.Tiny,
    color: Theme.Colors.Grayscale.SixtyFour,
  },
  switchLabel: {
    fontWeight: Theme.Typography.FontWeight.Medium,
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontStyle: 'normal',
    fontSize: Theme.Typography.FontSize.Tiny,
    color: Theme.Colors.Grayscale.OneThousand,
    marginTop: '24px',
  },
  switchInputGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
}))
