import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    padding: 0,

    '& .MuiPaper-root': {
      width: '100%',
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(20),
    margin: 0,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing(30),
    padding: `${spacing(40, 20)} !important`,
  },
  dialogActions: {
    padding: spacing(20),
    gap: spacing(12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: spacing(0, 20, 20, 20),
    },
  },
  title: {
    color: palette.primary.main,
    flexWrap: 'wrap',
    display: 'flex',
    fontWeight: 600,
    fontSize: spacing(24),
    textAlign: 'center',
    lineHeight: spacing(28),
  },
  text: {
    color: palette.gray[64],
    fontSize: spacing(18),
    flexWrap: 'wrap',
    display: 'flex',
    textAlign: 'center',
    lineHeight: spacing(30),
  },
  subTitle: {
    fontSize: spacing(16),
    color: palette.gray[64],
    lineHeight: spacing(24),
  },
  iconButton: {
    position: 'absolute',
    right: 14,
    top: 14,
    color: palette.grey[80],
  },
  button: {
    color: palette.error.main,
    border: `1px solid ${palette.error.main}`,
    padding: spacing(8, 16),
    margin: '0 !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    minWidth: spacing(120),

    backgroundColor: 'transparent',
    fontSize: spacing(14),
    borderRadius: '75px',

    '&:not(:disabled):hover': {
      opacity: '0.8',
    },

    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'visible',
      color: palette.gray[32],
      backgroundColor: palette.gray[4],
    },

    [breakpoints.down('sm')]: {
      padding: spacing(16),
      width: '100%',
    },
  },
  buttonFullRed: {
    background: palette.error.main,
    color: palette.gray[4],
    border: 'none',
    margin: '0 !important',

    '&:not(:disabled):hover': {
      background: palette.error.main,
      opacity: '0.8',
    },

    '&:disabled': {
      border: `1px solid ${palette.error.main}`,
    },
  },
  buttonRed: {
    background: 'transparent',
    color: palette.error.main,
    border: `1px solid ${palette.error.main}`,

    '&:not(:disabled):hover': {
      background: palette.error.main,
      color: palette.gray[4],
    },

    '&:disabled': {
      border: `1px solid ${palette.error.main}`,
    },
  },
  buttonBlue: {
    background: 'transparent',
    color: palette.primary.main,
    border: `1px solid ${palette.primary.main}`,

    '&:not(:disabled):hover': {
      background: palette.primary.main,
      color: palette.gray[4],
    },

    '&:disabled': {
      border: `1px solid ${palette.primary.main}`,
    },
  },
  buttonFullBlue: {
    background: palette.primary.main,
    color: palette.gray[4],
    border: 'none',

    '&:not(:disabled):hover': {
      background: palette.primary.main,
      opacity: '0.8',
    },

    '&:disabled': {
      border: `1px solid ${palette.primary.main}`,
    },
  },
  iconDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: spacing(80),
    height: spacing(80),
    borderRadius: '50%',
    backgroundColor: palette.yellow.lightest,
  },

  iconDiv2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: spacing(80),
    height: spacing(80),
    borderRadius: '50%',
    backgroundColor: palette.primary.lightest,
  },

  card: {
    padding: spacing(16),
    border: `1px solid ${palette.gray[32]}`,
    borderRadius: '10px',
    minHeight: spacing(100),
    maxHeight: spacing(300),
    width: '100%',
    display: 'flex',
    gap: spacing(16),
    marginTop: spacing(-8),

    overflowY: 'auto',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: spacing(6),
      height: spacing(6),
    },
    '&::-webkit-scrollbar-track': {
      background: '#E3E3E3',
      borderRadius: spacing(20),
      margin: spacing(15),
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: spacing(20),
      border: '3px solid gray',
    },
  },
  label: {
    fontSize: spacing(16),
    color: palette.gray[80],
  },
}))
