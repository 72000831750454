import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette }) => ({
  root: {
    padding: 0,

    '& .MuiPaper-root': {
      width: '860px',
      maxWidth: '860px',
    },
  },
  modalConteiner: {
    width: 860,
    '& .MuiTableCell-root': {
      padding: '22px 0',
      color: palette.gray[64],
    },
  },
  columnHeader: {
    fontWeight: 500,
  },
  chipReasons: {
    borderRadius: '16px',
    backgroundColor: palette.gray[4],
    padding: '4px 8px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}))
