import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Divider, Grid, Typography } from '@mui/material'
import { Button, DatePicker, Input, MaskedInput, Loader } from '@refera/ui-web'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import SwitchComponent from '_/components/switch'
import * as Modal from '_components/modal/generic-modal'
import { Danger as DangerIcon } from '@refera/ui-icons'
import Theme from '@refera/ui-core'

import { updateServiceOrder } from '_modules/service-orders/actions'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import { CLIENT_TYPE, CLIENT_TYPE_LABEL } from '_utils/user'
import { EMAIL_REGEX, SERVICE_ORDER_VALUES } from '_utils/constants'
import { parseNumber, parseToNumber, validationCNPJ, validationCPF } from '_utils/helpers'
import useRolePermission from '_hooks/use-role-permission'
import RatingModal from '_components/modal/rating-modal'
import Svg, { ICON } from '_components/svg'
import Select from '_components/common/select'

import { PhoneInputField, TimePickerField, PhoneFieldContext } from './textfield'
import useStyles from './styles'
import { dataFormatter, payloadFormatter, PAYLOAD_KEY } from './data-formatter'
import { useToast } from '_/hooks/use-toast'

const FormEditRequester = ({ open, onCancel, user }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const formRef = useRef()

  const { isIntermediary, checkUserPermission } = useRolePermission()
  const [isLoading, setIsLoading] = useState(false)
  const { showToast } = useToast()
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const defaultValues = useMemo(() => {
    const data = dataFormatter(user)

    return {
      ...user,
      ...data,
      hasAnotherBudget: serviceOrder?.hasAnotherBudget,
    }
  }, [user, serviceOrder])

  const methods = useForm({
    mode: 'all',
    defaultValues,
  })

  const [hasAnotherBudget, setHasAnotherBudget] = useState(defaultValues.hasAnotherBudget === 'yes')

  const { watch, register, control, errors, setValue } = methods

  const {
    fields: phones,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: 'phones',
  })

  useEffect(() => {
    setHasAnotherBudget(defaultValues.hasAnotherBudget === 'yes')
  }, [defaultValues.hasAnotherBudget])

  const handleSwitchChange = event => {
    const isChecked = event.target.checked
    setHasAnotherBudget(isChecked)
    methods.setValue('hasAnotherBudget', isChecked ? 'yes' : 'no')
  }

  const { fields: date } = useFieldArray({
    control,
    name: 'date',
  })

  const { fields: time } = useFieldArray({
    control,
    name: 'time',
  })

  const contactCpfCnpjWatch = watch('contactCpfCnpj')

  const [exportErrorRemoveContactNumber, setExportErrorRemoveContactNumber] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  })

  const clientOptions = useMemo(() => {
    return Object.values(CLIENT_TYPE).map(type => ({
      value: type,
      label: CLIENT_TYPE_LABEL[type],
    }))
  }, [])

  const inputMask = useMemo(() => {
    const cpfCnpjLength = String(parseToNumber(contactCpfCnpjWatch ?? user?.contactCpfCnpj)).length

    return cpfCnpjLength <= 11 ? '###.###.###-####' : '##.###.###/####-##'
  }, [contactCpfCnpjWatch])

  // TODO: Move it to helpers folder
  const validateCpf = value => {
    if (parseNumber(value).length === 11) {
      return validationCPF(value) || 'CPF inválido'
    }

    if (parseNumber(value).length === 14) {
      return validationCNPJ(value) || 'CNPJ inválido'
    }

    return true
  }

  // TODO: Move it to helpers folder
  const isValidEmail = email => {
    if (EMAIL_REGEX.test(email)) {
      return true
    }
    return 'Conteúdo inválido'
  }

  const cpfRules = useMemo(
    () => ({
      validate: { validateCpf },
    }),
    [validateCpf]
  )

  const emailRules = useMemo(
    () => ({
      validate: { isValidEmail },
    }),
    [isValidEmail]
  )

  const handleSelectChange = useCallback(
    event => setValue(event.target.name, event.target.value),
    []
  )

  const handlePermissionChange = useCallback(
    event =>
      checkUserPermission({
        permission: PERMISSIONS.REQUESTER_DATE_SUGGESTION,
        permissionAction: [PERMISSIONS_ACTIONS.ADD, PERMISSIONS_ACTIONS.EDIT],
        functionAccess: () => handleSwitchChange(event),
      }),
    [checkUserPermission, handleSwitchChange]
  )

  const onSubmit = useCallback(
    data => {
      if (Object.keys(errors).length) {
        return
      }

      const { phones: phoneValues, date: dateValues, time: timeValues, ...formData } = data

      const formattedPhones = payloadFormatter(phoneValues, PAYLOAD_KEY.PHONES)
      const formattedDates = payloadFormatter(dateValues, PAYLOAD_KEY.DATE)
      const formattedTime = payloadFormatter(timeValues, PAYLOAD_KEY.TIME)

      const payloadForm = {
        ...formData,
        ...formattedPhones,
        ...formattedDates,
        ...formattedTime,
      }

      setIsLoading(true)
      dispatch(updateServiceOrder(payloadForm))
        .then(() => {
          setIsLoading(false)
          showToast({ type: 'success' })
          onCancel()
        })
        .catch(() => {
          setIsLoading(false)
          showToast({ type: 'error' })
        })
    },
    [dispatch]
  )

  return (
    <>
      <Loader open={isLoading} hasBackdrop />
      <form ref={formRef} className={styles.modal} id="requester-data">
        <FormProvider {...methods}>
          <Modal.Root open={open} onClose={onCancel} disableEnforceFocus>
            <Modal.TitleModal>
              <Typography className={styles.modalTitle}>Dados do solicitante</Typography>
            </Modal.TitleModal>
            <Divider className={styles.divider} />
            <Modal.Content className={styles.form}>
              <Grid className={styles.clientType}>
                <Controller
                  control={control}
                  name="clientType"
                  // eslint-disable-next-line no-unused-vars
                  render={({ onChange, name, ...fieldProps }) => (
                    <Select
                      label="Tipo do cliente"
                      onChange={handleSelectChange}
                      name={name}
                      options={clientOptions}
                      {...fieldProps}
                    />
                  )}
                />
              </Grid>
              <Grid className={styles.cpf}>
                <Controller
                  name="contactCpfCnpj"
                  control={control}
                  rules={cpfRules}
                  render={({ onChange, value }) => (
                    <MaskedInput
                      format={inputMask}
                      mask=" "
                      label="CPF / CNPJ"
                      value={value}
                      style={{ fontSize: 14 }}
                      onChange={currentValue => onChange(currentValue.formattedValue)}
                      fullWidth
                      error={Boolean(errors?.contactCpfCnpj?.message)}
                      errorMessage={errors?.contactCpfCnpj?.message}
                    />
                  )}
                />
              </Grid>
              <Grid className={styles.name}>
                <Input
                  label="Nome"
                  name="contactName"
                  inputRef={register({ required: 'Campo obrigatório' })}
                  style={{ fontSize: 14 }}
                  fullWidth
                  error={Boolean(errors?.contactName?.message)}
                  errorMessage={errors?.contactName?.message}
                />
              </Grid>
              <Grid className={styles.email}>
                <Input
                  label="E-mail"
                  name="contactEmail"
                  inputRef={register(emailRules)}
                  style={{ fontSize: 14 }}
                  fullWidth
                  error={Boolean(errors?.contactEmail?.message)}
                  errorMessage={errors?.contactEmail?.message}
                />
              </Grid>
              <Grid className={styles.phones}>
                {phones.map((field, index) => (
                  <PhoneFieldContext.Provider
                    key={field.id}
                    value={{
                      index,
                      isToAddTelephone: Boolean(index),
                      label: !index ? 'Telefone' : null,
                      removePhone,
                    }}
                  >
                    <PhoneInputField
                      name={`phones.${index}.data`}
                      rules={{ required: 'Campo obrigatório' }}
                      defaultValue={field.data}
                    />
                  </PhoneFieldContext.Provider>
                ))}

                {phones.length <= 2 && (
                  <Button
                    className={styles.iconButton}
                    variant="outline"
                    onClick={appendPhone}
                    classes={{ root: styles.iconButton }}
                  >
                    <Svg className={styles.icon} type={ICON.ADD_CIRCLE} />
                    Adicionar outro número
                  </Button>
                )}
              </Grid>
              <Grid className={styles.date}>
                <Typography className={styles.labelTime}>Datas sugeridas</Typography>

                <Grid container spacing={8}>
                  {date.map((field, index) => (
                    <Grid key={field.id} item xs={12}>
                      <Controller
                        control={control}
                        name={`date.${index}.data`}
                        defaultValue={field.data}
                        as={
                          <DatePicker
                            variant="inline"
                            format="dd/MM/yyyy"
                            placeholder="DD/MM/AAAA"
                            invalidDateMessage="Insira uma data válida"
                            refuse={/[^\d\\.]+/gi}
                            defaultValue={field.data}
                            disabled={isIntermediary}
                          />
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
                <div className={styles.switchInputGroup}>
                  <label className={styles.switchLabel}>Cliente possui outro orçamento</label>
                  <Controller
                    control={methods.control}
                    name={SERVICE_ORDER_VALUES.HAS_ANOTHER_BUDGET}
                    defaultValue={defaultValues?.hasAnotherBudget === 'no'}
                    render={() => (
                      <SwitchComponent
                        checked={hasAnotherBudget}
                        onChange={handlePermissionChange}
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid className={styles.hour}>
                <Typography className={styles.labelTime}>Horários sugeridos</Typography>

                <Grid container spacing={8}>
                  {time.map((field, index) => (
                    <Grid key={field.id} item xs={12} container spacing={4}>
                      <Grid item xs={6}>
                        <TimePickerField
                          name={`time.${index}.startTime`}
                          defaultValue={field.startTime}
                          fieldToCompare={`time.${index}.endTime`}
                          disabled={isIntermediary}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TimePickerField
                          name={`time.${index}.endTime`}
                          defaultValue={field.endTime}
                          fieldToCompare={`time.${index}.startTime`}
                          disabled={isIntermediary}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid container spacing={8} wrap="nowrap">
                  <Grid item>
                    <DangerIcon color={Theme.Colors.Yellow.Base} fontSize={16} />
                  </Grid>
                  <Grid item>
                    <Typography className={styles.timeInfo}>
                      Deve haver um intervalo de pelo menos duas horas entre os horários sugeridos
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Modal.Content>
            <RatingModal
              ratingModal={exportErrorRemoveContactNumber}
              setRatingModal={setExportErrorRemoveContactNumber}
            />
            <Modal.Actions>
              <Modal.ButtonRed onClick={onCancel}>Cancelar</Modal.ButtonRed>
              <Modal.ButtonFullBlue
                form="requester-data"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                Salvar
              </Modal.ButtonFullBlue>
            </Modal.Actions>
          </Modal.Root>
        </FormProvider>
      </form>
    </>
  )
}

export default React.memo(FormEditRequester)
