import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing, palette }) => ({
  fieldList: {
    display: 'grid',
    gap: spacing(24),
  },
  deleteButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-18px',
    paddingTop: '14px',
  },
  deleteButton: {
    padding: spacing(8),
    minWidth: '36px',
  },
  fullWidth: {
    width: '100%',
  },
  label: {
    marginTop: '10px',
    fontSize: Theme.Typography.FontSize.Tiny,
    color: Theme.Colors.Grayscale.ThirtyTwo,
  },
  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: props => {
      if (props?.poolAvailable) {
        return props?.multipleTradesman ? '81%' : '85%'
      }

      return props?.multipleTradesman ? '94%' : '85%'
    },
    marginTop: '20px',
    padding: '0px 8px 0 50px',
    flex: props => {
      if (props?.poolAvailable) {
        if (!props?.multipleTradesman) return 1
        return 'unset'
      }
      return props?.multipleTradesman ? 'unset' : 1
    },
    paddingRight: props => {
      if (props?.poolAvailable) {
        return props?.multipleTradesman ? '10px' : '8px'
      }
      return props?.multipleTradesman ? '8px' : 0
    },
    marginBottom: spacing(16),
  },
  selectWrapperWithPool: {
    width: '85%',
    padding: '0px 28px 0 50px',
  },
  roundLabel: {
    fontSize: `${Theme.Typography.FontSize.Tiny} !important`,
    color: `${Theme.Colors.Grayscale.ThirtyTwo} !important`,
  },
  select: {
    width: '100%',
  },
  selectLabel: {
    fontWeight: '400',
    width: '100%',
  },
  showReferaSuggestions: {
    margin: '0 0 16px',
  },
  runPool: {
    alignSelf: 'center',
  },
  customSelectLabel: {
    color: '#B4B4B4',
    fontSize: '12px',
    marginTop: '14px !important',
    marginBottom: '-8px !important',
    fontFamily: 'Roboto',
    fontWeight: '400',
  },
  customSelect: {
    width: props => {
      if (props?.poolAvailable) {
        return props?.multipleTradesman ? '36px' : '46px'
      }

      return props?.multipleTradesman ? '36px' : '46px'
    },
  },
  addProviderContainer: {
    marginTop: spacing(16),
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: palette.primary.main,
      color: palette.gray[4],
    },
    '& .MuiTooltip-arrow': {
      color: palette.primary.main,
    },
  },
  tooltipBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  li: {
    width: '100%',
  },
  svgIcon: {
    width: '40px',
    height: '40px',
  },
}))
